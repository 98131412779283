

@media screen and (max-width: 768px) {
    .container{
        width: 100%;
        height: fit-content;
    }
    .side-navigation{
        width: 100%;
        height: fit-content;
        margin-bottom: 10px;
    }
    
}