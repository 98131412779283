.container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  margin: 1rem 0;
  border-radius: 30px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.box1 {
  background-color: rgba(0, 81, 255, 0.374);
  /* background-color: #4299e1; */
  border-radius: 30px 0% 0% 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
}
.title {
  font-size: 1.3rem;
  font-weight: 600;
}
.skills {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 70%;
}
.education {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 30px;
}
.details {
  display: flex;

  flex-direction: column;
}

.image {
  margin-top: 10%;
  border-radius: 50%;
}

.smtitle {
  font-size: 1.1rem;
  font-weight: 500;
}
.sminfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.sdetails {
}
.section {
  display: flex;
  flex-direction: row;
}
.box2 {
  background-color: rgb(255, 255, 255);
  border-radius: 0% 30px 30px 0%;
  margin: 20px;
}
.section {
  padding: 10px;
}
.divide {
  border-bottom: 2px solid rgb(0, 0, 0);
  width: 100%;
}
.subheading {
  font-size: 1.1rem;
  font-weight: 600;
}
.radialBar {
  /* margin: 10px; */
  border-radius: 30px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(0, 81, 255, 0.3);
  /* width: 70%; */
}
.des {
  width: 90%;
}
.part2 {
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .details {
    flex-direction: column;
  }
}
