.resumeModal::-webkit-scrollbar {
  width: 5px;
}

.resumeModal::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.resumeModal::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.resumeModal::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}