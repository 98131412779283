.backgroundImage {
  background-image: url("../../images/6.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
}
@media screen and (max-width: 768px) {
  .backgroundImage {
    background-image: none;
  }
  .sliding {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .main-heading {
    font-size: 3rem;
  }
}
