@media screen and (max-width: 640px) {
  .contain {
    width: 100%;
    height: fit-content;
  }
  .heading{
    padding-left: 10px;
  }
  .responsive-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 24rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .box {

    margin-bottom: 8px;
    
  }
  .tables{
    width: 100%;
    padding-bottom: 10px;
  }
  .profile{
    padding-left: 8px;
    padding-right: 8px;
  }
  .candidate-heading{
    display: flex;
    flex-direction: column;
  }
}
