@media screen and (max-width: 700px) {
  .contain {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3.5fr;
  }
  .outer-contain {
    grid-row-start: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr 1fr;
  }
  .sidebar {
    width: 100%;
    height: fit-content;
  }
  .navigation {
    margin: 0;
    padding: 0;
    width: 100%;
    grid-row-start: 0;
  }
  .inner-container {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
  }
  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    grid-row-start: 1;
    column-gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
  .box {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .footer {
    width: 100%;
    height: fit-content;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
