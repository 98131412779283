/* for smaller devices */

@media (max-width: 700px) {
  .contain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .outer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .inner-container {
    width: 100%;
    height: fit-content;
  }
  .sidebar {
    width: 100%;
    height: fit-content;
    
  }
}
