@media screen and (max-width: 700px) {
  .navigation {
    width: 100%;

    display: flex;
  }
  .icon {
    margin-right: 50px;
  }
}
