@import "tailwindcss/base";
@import "tailwindcss/components";
@tailwind utilities;

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply text-xl font-bold;
}

h4 {
  @apply text-lg font-bold;
}

h5 {
  @apply text-base font-bold;
}

h6 {
  @apply text-sm font-bold;
}

p {
  @apply text-base;
}

ul {
  @apply list-disc;
}

li {
  @apply ml-4;
}

ol {
  @apply list-decimal;
}
