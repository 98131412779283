/* global.css */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 700px) {
  .checkbox {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
}
