/* styles.css */
@keyframes zoomRotate {
    0% {
        transform: scale(1) rotate(0);
    }
    50% {
        transform: scale(1.2) rotate(10deg);
    }
    100% {
        transform: scale(1.1) rotate(5deg);
    }
}

.zoom-rotate-container {
    position: relative;
    
}

.zoom-rotate-img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.zoom-rotate-img:hover {
    animation: zoomRotate 0.6s ease infinite alternate;
}
