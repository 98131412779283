@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);


.card {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  padding: 30px 50px;
  background: #fff;
  cursor: pointer;
  margin-bottom: 60px;
  
}

.card:hover {
  height: 380px;
    
}
/*320 for lg devices*/

.imgbox {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  z-index: 99;
}

.content {
    
    text-align: center;
    opacity: 0;
    transition: 0.3s;
    position: relative; 
    bottom: 193px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    height: 20px;
    
  }

.card:hover > .content {
  opacity: 1;
  
}

.content h2 {
  color: #7f9ead;
}