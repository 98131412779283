.icontainer {
  display: grid;
  grid-template-columns: 4;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 3;
  /* max-width: 50%; */
}
.box2 {
  grid-column-start: 3;
  grid-column-end: 4;
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .icontainer {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .box1{
    margin-left: 5px;
    margin-right: 5px;
  }
  .badge{
    margin-top: auto;
    margin-left: 0;
  }
  .box2  {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    
  }
}
